<template>
  <template v-if="home">
    <section id="hero" class="d-flex flex-column justify-content-center">

      <template v-if="home.site_inicio && home.site_inicio.video">
        <video @loadedmetadata="videoLoaded" playsinline autoplay="autoplay" loop="true" muted="true" poster="" preload="auto" alt="slidebg1" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
        <source :src="'/videos/inicio/' + home.site_inicio.video" type="video/mp4"></video>
      </template>

      <div class="container">
        <div class="row justify-content-center">
            <div id="carousel-quotes" class="carousel" data-bs-ride="carousel">
              <div class="carousel-inner">
                <template v-if="home.site_inicio_quotes && home.site_inicio_quotes.length">
                    <div  v-for="(quote,index) of home.site_inicio_quotes" v-bind:key="quote.id_quote" :class="index == 0 ? 'active' : ''" class="carousel-item" data-bs-interval="10000">
                      <div class="xcarousel-caption xd-none xd-md-block">
                        <h1 class="quotes-anim">{{ quote.quote }}</h1>
                        <h2 class="quotes-anim">{{ quote.author }}</h2>
                      </div>
                    </div>
                </template>
              </div>					
            </div>
        </div>
      </div>
      <div class="home-bottom">
        <div class="container text-center">
          <div class="move bounce">
            <a href="#about" class="bi bi-chevron-down">
            </a>
          </div>
        </div>
      </div>
    </section><!-- End Hero -->

    <main id="main">

      <!-- ======= About Us Section ======= -->
      <SectionComponent v-if="home?.site_inicio" :id="'about'" :class="'about'" :title="home.site_inicio.institucional_titulo" :subtitle="home.site_inicio.institucional_texto" :text="home.site_inicio.institucional_desarrollo">
        <div class="d-flex justify-content-center">
          <ButtonComponent :href="'/institucional/'" :caption="'Leer Más ...'" :hollow="true"/>
        </div>
      </SectionComponent>
      <!-- End About Us Section -->

      <!-- ======= Services Section ======= -->
      <!--SectionComponent v-if="home?.site_inicio" :id="'services'" :class="'services'" :title="home.site_inicio.accesos_titulo" :text="home.site_inicio.accesos_texto">
        <div v-if="home.site_inicio_accesos && home.site_inicio_accesos.length" class="row">
          <div v-for="item_acceso of home.site_inicio_accesos" v-bind:key="item_acceso.id_acceso" class="col-lg-4 col-md-6 d-flex align-items-stretch mb-3">
            <div class="icon-box">
              <a :href="isURL(item_acceso.link) ? item_acceso.link : item_acceso.link"  :target="isURL(item_acceso.link) ? '_BLANK' : ''" >
                <div class="icon"><i :class="'bx bx-' + item_acceso.icono"></i></div>
                <h4>{{ item_acceso.titulo }}</h4>
                <p>{{ item_acceso.texto }}</p>
              </a>
            </div>							
          </div>
        </div>
      </SectionComponent-->
      <!-- End Services Section -->

      <!-- ======= Cta Section ======= -->
      <SectionComponent v-if="home?.site_novedades" :title="home.site_inicio.novedades_titulo" :text="home.site_inicio.novedades_texto">
        <div class="container">
          <div v-if="home.site_novedades && home.site_novedades.length" class="noticias">
            <NoticiasDestacadasComponent :noticias="home.site_novedades_destacadas"/>
          </div>
          <NoticiasComponent :noticias="home.site_novedades"/>
          <div class="d-flex justify-content-center">
            <ButtonComponent :href="'/novedades/'" :caption="'Ver todas las novedades...'" :hollow="true"/>
          </div>
        </div>
      </SectionComponent>
      

      <SectionComponent v-if="home?.site_eventos && home.site_eventos.length" :title="home.site_inicio.eventos_titulo" :text="home.site_inicio.eventos_texto">
        <EventosComponent :eventos="home.site_eventos" :showText="false"/>
        <div class="d-flex justify-content-center">
          <ButtonComponent :href="'/eventos/'" :caption="'Ver todas las efemérides...'" :hollow="true"/>
        </div>
      </SectionComponent>
      
      
      <!-- ======= Album Section ======= -->
      <SectionComponent v-if="home?.site_inicio_album" :id="'album'" :class="'album'" :title="home.site_inicio.album_titulo ? home.site_inicio.album_titulo : 'Galería De Fotos'" :text="home.site_inicio.album_texto">
        <AlbumComponent :albums="home.site_inicio_album"/>
      </SectionComponent>
      <!-- End Album Section -->

      <!-- ======= Contact Section ======= -->
      <SectionComponent v-if="home?.site_inicio" :id="'contact'" :class="'contact'" :title="home.site_inicio.contacto_titulo" :text="home.site_inicio.contacto_texto">
        <div class="container">
          <div class="row mt-5">
            <div class="col-lg-4">
              <DireccionesComponent />
            </div>
            <div class="col-lg-8 mt-5 mt-lg-0">
              <FormConsultaComponent />
            </div>
          </div>
        </div>
      </SectionComponent>
      <GoogleMapsComponent v-if="home?.site_inicio && home.site_inicio.map" :src="home.site_inicio.map" />
      
    </main><!-- End #main -->	
  </template>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import MesComponent from '../components/general/MesComponent.vue'
import TitleComponent from '../components/general/TitleComponent.vue'
import SectionComponent from '../components/general/SectionComponent.vue'
import ButtonComponent from '../components/general/ButtonComponent.vue'
import NoticiasComponent from '../components/noticias/NoticiasComponent.vue'
import NoticiasDestacadasComponent from '../components/noticias/NoticiasDestacadasComponent.vue'
import EventosComponent from '../components/eventos/EventosComponent.vue'
import GoogleMapsComponent from '../components/general/GoogleMapsComponent.vue'
import DireccionesComponent from '../components/general/DireccionesComponent.vue'
import FormConsultaComponent from '../components/general/FormConsultaComponent.vue'
import AlbumComponent from '../components/general/AlbumComponent.vue'

export default {
  name: 'HomeView',
  components: {
    MesComponent,
    TitleComponent,
    SectionComponent,
    ButtonComponent,
    NoticiasComponent,
    NoticiasDestacadasComponent,
    EventosComponent,
    GoogleMapsComponent,
    DireccionesComponent,
    FormConsultaComponent,
    AlbumComponent
  },
  setup(){
    //const home = computed(() => store.state.home)
    // Define a reactive variable to store your data
    const home = ref(null)
    const store = useStore()
    
    const videoLoaded = () => {
      store.commit('setLoading', false)
    }
    const fetchHome = async () => {
      store.commit('setLoading', true)
      try {
        const response = await fetch('home', { 'auth': true })
        const responseData = await response.json()
        // Assign the fetched data to the reactive variable
        home.value = responseData
      } catch (error) {
        console.error('Error fetching data:', error)
      } finally {
        store.commit('setLoading', false)
      }
    }

    // Call the fetchData function when the component is mounted
    onMounted(() => {
      store.commit('setLoading', true)
      fetchHome()
    })

    const isURL = (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?','i'); // fragment locator
      return pattern.test(str);
    }
    
    return {home, isURL, videoLoaded}
  }

}
</script>