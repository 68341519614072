<template>
{{ mesText }}
</template>

<script>
import { onMounted } from 'vue';

export default{
    name: 'MesComponent',
    props: {
        'date':{
            type: String
        }
    },
    data() {
        return {
            mes: '',
            meses : ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
        }
    },
    computed: {
        mesText() {
            const d = new Date()
            if(!this.mes >= 1 && !this.mes <= 12) 
                this.mes= d.getMonth() + 1
            return this.meses[this.mes - 1]
        }
    },
    mounted() {
        const d = new Date(this.date);
        this.mes = d.getMonth() + 1
    }
    
}

</script>