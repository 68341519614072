<template>
  <div class="row row-cols-1 g-4 noticias mb-5" :class="thumbnails ? ['row-cols-sm-1','row-cols-md-2','row-cols-lg-3'] : 'event-list'">
    <div v-for="item_noticia of noticias" class="col noticias mb-3">
      <div class="card h-100">
        <div class="backgroundEffect"></div>
        <div class="pic"> 
          <router-link :to="{ name: 'novedad', params: { id: item_noticia.id_noticia }, query: filterArray($route.query, 'page') }">
            <img v-if="item_noticia.imagen" class="card-img-top" width="440" height="150" :src="'/images/news/'+item_noticia.imagen" alt="">
            <img v-else width="450" height="250" src="/assets/images/novedades/bg-novedades.jpg" class="img-fluid card-img-top left">
          </router-link>
          
          <div class="date">
            <span class="day">{{ getDay(item_noticia.pubdate) }}</span>
            <span class="month">
              <MesComponent :date="item_noticia.pubdate"/>
            </span>
            <span class="year">{{  getYear(item_noticia.pubdate) }}</span>
          </div>
        </div>
        
        <div class="card-body content d-flex flex-column h-100 mb-3">
          <div class="text-start mb-auto">
            <router-link :to="{ name: 'novedad', params: { id: item_noticia.id_noticia }, query: filterArray($route.query, 'page') }">
              <span class="badge bg-gray px-2 py-1 shadow-1-strong bg-light text-dark mt-3 me-1">{{ item_noticia.area }}</span> 
              <span class="badge bg-gray px-2 py-1 shadow-1-strong bg-light text-dark mt-3">{{ item_noticia.categoria }}</span>
              <h5 class="card-title h-1 mt-1">{{ item_noticia.titulo }}</h5>
              <p class="card-text text-muted mt-1">{{ truncate(item_noticia.texto, 100) }}</p>
            </router-link>
          </div>
          <!-- div class="mb-3 mt-3">
            <router-link :to="{ name: 'novedad', params: { id: item_noticia.id_noticia }, query: filterArray($route.query, 'page') }">
              <div class="btn btn-primary">Leer más...<span class="fas fa-arrow-right"></span></div>
            </router-link>												
          </div -->
        </div>
        
      </div>
    </div>
  </div>								
</template>

<script>
import { getDay, getYear, truncate } from '../../utils/common.utils.js'
import MesComponent from '../general/MesComponent.vue'

export default{
  name: 'NoticiasDestacadasComponent',
  components:{
    MesComponent
  },
  props: {
    'noticias':{
      type: Array
    },
    'thumbnails': {
      type: Boolean,
      default: true
    },
    'showText':{
      type: Boolean,
      default: true
    }
  },
  setup(){
    const filterArray = (array, propName) => {
      const { [propName]: excluded, ...rest } = array
      return rest
    }
    return {
      filterArray,
      getDay,
      getYear,
      truncate
    }
  }
}
</script>