import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale: 'es', // Default locale
  fallbackLocale: 'es', // Fallback locale
  messages: {
    en: require('./locales/en.json'), // English translations
    es: require('./locales/es.json') // Spanish translations
    // Add more languages as needed
  }
})

export default i18n