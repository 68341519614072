<template>
    <div class="info">
        <div v-if="datos_site?.data && datos_site.data.direccion" class="address">
            <i class="ri-map-pin-line"></i>
            <h4>Ubicación:</h4>
            <p>{{ datos_site.data.direccion }}</p>
        </div>
        <div v-if="datos_site?.data && datos_site.data.telefono1" class="address">
            <i class="ri-phone-line"></i>
            <h4>Teléfono:</h4>
            <p>{{ datos_site.data.telefono1 }}</p>
        </div>
        <div v-if="datos_site?.data && datos_site.data.email" class="address">
            <i class="ri-mail-line"></i>
            <h4>Email:</h4>
            <p>{{ datos_site.data.email }}</p>
        </div>
        
        <div v-if="datos_site.contacto_areas" v-for="contacto of datos_site.contacto_areas" v-bind:key="contacto.id_contacto" class="email">
            <h4>{{ contacto.area }}</h4>
            <p v-if="contacto.direccion"><span class="ri-map-pin-fill"></span> {{ contacto.direccion }}</p>
            <p v-if="contacto.telefono"><span class="ri-phone-fill"></span> {{ contacto.telefono }}</p>
            <p v-if="contacto.email"><span class="ri-mail-fill"></span> <a href="mailto:{{ contacto.email }}">{{ contacto.email }}</a></p>
            <p v-if="contacto.whatsapp"><span class="ri-whatsapp-fill"></span> <a href="https://wa.me/{{ contacto.whatsapp }}?text=" target="_BLANK">{{ contacto.whatsapp }}</a></p>
            <p v-if="contacto.horario"><span class="ri-clock-fill"></span> {{ contacto.horario }}</p>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'

export default{

    name: 'DireccionesComponent',
    props: {
        'src':{
            type: String
        },
        'height':{
            type: Number,
            default: 350
        }
    },
    components: {
    },
    setup(){
        const store = useStore()
        const datos_site = computed(() => store.state.datos)
        return {datos_site}
    }
}
</script>