<template>
  <div class="row row-cols-1 g-4 mb-5" :class="thumbnails ? ['row-cols-sm-1','row-cols-md-2','row-cols-lg-3'] : 'event-list'">
    <div v-for="item_evento of eventos" class="col">
      <div class="card h-100">
        <div class="image-container">
          <div class="bottom-right">{{ formatDate(item_evento.pubdate, 'd/M/yyyy') }}</div>
          <div class="top-right">
            <div class="tags-list mb-2">
              <span class="tags-list-week text-uppercase">
                <i class="bx bx-calendar" aria-hidden="true"></i> 
                <SemanaComponent :date="item_evento.pubdate"/>
              </span>
              <span v-if="item_evento.timestart || item_evento.timeend" class="tags-list-hour text-uppercase">
                <i class="bx bx-alarm" aria-hidden="true"></i>
                <template v-if="item_evento.timestart">{{ removeSeconds(item_evento.timestart) }}</template>
                <template v-if="item_evento.timestart && item_evento.timeend"> - </template>
                <template v-if="item_evento.timeend">{{ removeSeconds(item_evento.timeend) }}</template>
              </span>
              <span v-if="item_evento.area" class="tags-list-place text-uppercase">
                <i class="bx bx-map" aria-hidden="true"></i> {{ item_evento.area }}
              </span>
            </div>
          </div>
          <div class="bottom-left">
            <div class="thumbnail">
              <div class="thumbnail-date">
                <span class="thumbnail-date-day">{{ formatDate(item_evento.pubdate, 'd') }}</span>
                <span class="thumbnail-date-month"><MesComponent :date="item_evento.pubdate"/></span>
              </div>
            </div>
          </div>
          <img class="card-img-top" :src="item_evento.imagen ? '/images/events/'+item_evento.imagen : '/assets/images/novedades/bg-novedades.jpg'" alt="">
          <div class="image-overlay text-over text-center d-flex justify-content-center align-items-center text-uppercase">
            <div class="event-title">{{ item_evento.titulo }}</div>
          </div>
        </div>
        <div v-if="showText" :class="{ small: thumbnails }" class="card-footer">
          {{ truncate(item_evento.texto, 300) }}
        </div>
      </div>
    </div>
  </div>	
</template>

<script>
import { format, formatDistance, formatRelative, subDays } from 'date-fns'
import { getDay, getYear, truncate } from '../../utils/common.utils.js'
import MesComponent from '../general/MesComponent.vue'
import SemanaComponent from '../general/SemanaComponent.vue'

export default{
  name: 'EventosComponent',
  components:{
    MesComponent,
    SemanaComponent
  },
  props: {
    'eventos':{
      type: Array
    },
    'thumbnails': {
      type: Boolean,
      default: true
    },
    'showText':{
      type: Boolean,
      default: true
    }
  },
  setup(){
    const filterArray = (array, propName) => {
      const { [propName]: excluded, ...rest } = array
      return rest
    }

    const formatDate = (dateToformat, formatDate) => {
      const d = new Date(dateToformat)
      return format(d, formatDate)
    }

    const removeSeconds = (timeString) => {
      const [hours, minutes, seconds] = timeString.split(":")
      return `${hours}:${minutes}`
    }

    return {
      removeSeconds,
      formatDate,
      filterArray,
      getDay,
      getYear,
      truncate
    }
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.thumbnail-date-day {
  color: rgb(255, 255, 255);
}

.thumbnail-date-month {
  color: rgb(255, 255, 255);
}

.thumbnail {
  -webkit-touch-callout: none;
  border-radius: 3px;
  box-sizing: initial;
  height: 52px;
  min-width: 52px;
  overflow: hidden;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 52px;
  background-color: #05264f;
}

.grey {
  background-color: #bababaa7;
}

.thumbnail .thumbnail-date span,
.thumbnail .thumbnail-date span {
  display: inline-block;
  line-height: 0.8;
}

.thumbnail .thumbnail-date .thumbnail-date-day {
  font-size: 24px;
  font-weight: 700;
  margin-top: 4px;
  position: relative;
  text-align: center;
  top: 4px;
  width: 100%;
}

.thumbnail .thumbnail-date .thumbnail-date-month {
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.tags-list-week {
  background-color: #033d22;
}

.tags-list-place {
  background-color: #6d0000;
}

.tags-list-hour {
  background-color: #15025c;
}

.tags-list span {
  border-radius: 2px;
  color: #fff;
  cursor: default;
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 4px;
  margin-right: 5px;
  padding: 4px 6px;
  position: relative;
}

.text-over {
  font-size: 1.3em;
  font-weight: 900;
  color: #fff;
  padding: 20px;
}

.card {
  /*border: 0 !important;*/
  border-radius: 0 !important;
}

.card-img-top {
  width: 100%;
  height: 18vw;
  object-fit: cover;
  border-radius: 0 !important;
}

.image-container {
  position: relative;
  overflow: hidden;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 30%);
  z-index: 1;
}


.tags-list-town {
  background-color: #05264f;
}
/* Top left text */
.bottom-left {
  position: absolute;
  bottom: 14px;
  left: 16px;
  z-index: 2;
}

.bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #fff;
  font-size: 10px;
  z-index: 2;
  text-shadow: 0 0 3px #000000;
}

.top-right {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
}

.thumbnail {
  box-shadow: 0.5rem 0.5rem #30507085, -0.5rem -0.5rem #95badf85;
}

.card {
  border: 1px solid #eee;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.tags-list span {
  font-size: 14px !important;
}

.event-title {
  text-shadow: 0px 0px 5px #000000;
}

.event-list .card-img-top {
  height: 23vw;
}

/*.card {
    transition: all 0.5s ease;
    cursor: pointer;
    user-select: none;
    z-index: 10;
    overflow: hidden
}

.card:hover {
    transform: scale(1.025);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 10px
}*/

@media (max-width: 575.98px) { 
  .event-list .card-img-top,
  .card-img-top {
    height: 53vw;
  } 
}


/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { 
  .event-list .card-img-top,
  .card-img-top {
    height: 40vw;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) { 
  .card-img-top {
    height: 23vw;
  }
  .event-list .card-img-top{
    height: 30vw;
  }
}

/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) { 
  .card-img-top {
    height: 17vw;
  }
  .event-list .card-img-top{
    height: 22vw;
  }
}

</style>