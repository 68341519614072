<template>
  <div class="album">
    <div v-if="albums?.albums && albums?.albums?.length > 1" class="row">
      <div class="col-lg-12 d-flex justify-content-center">
        <ul id="album-flters">
          <li data-filter="*" @click="changeFilter('')" :class="activeFilter == '' ? 'filter-active' : ''">Todas la fotos</li>
          <li v-for="(album, index) of albums.albums" v-bind:key="index" data-filter=".filter-{{ album.id_album }}" :class="album.id_album == activeFilter ? 'filter-active' : ''" @click="changeFilter(album.id_album, index)">{{ album.nombre_album }}</li>
        </ul>
      </div>
    </div>
    <div class="row album-container">
      <template v-if="filteredImages()" v-for="(album_imagen, index) of filteredImages()" v-bind:key="index">
        <!--transition-group name="zoom-fade"-->
          <div :class="'col-lg-4 col-md-6 album-item filter-' + album_imagen.id_album + ' ' + album_imagen.imagen_titulo">
            <a @click.prevent="selectPhoto(album_imagen, index)" :href="'/images/albums/'+album_imagen.id_album+'/'+album_imagen.imagen" data-gallery="albumGallery" class="album-lightbox preview-link" :title="album_imagen.imagen_titulo">
              <img :src="'/images/albums/'+album_imagen.id_album+'/'+album_imagen.imagen" class="img-fluid" alt="">
              <div v-if="album_imagen.imagen_titulo || album_imagen.imagen_descripcion || album_imagen.nombre_album" class="album-info">
                <h4 v-if="album_imagen.imagen_titulo">{{ album_imagen.imagen_titulo }}</h4>
                <p v-if="album_imagen.nombre_album || album_imagen.imagen_descripcion">{{ album_imagen.nombre_album }}<span v-if="album_imagen.nombre_album && album_imagen.imagen_descripcion"> - </span>{{ album_imagen.imagen_descripcion }}</p>
                <!-- span class="details-link" title="Más detalles"><i class="bx bx-link"></i></span -->
              </div>
            </a>
          </div>
        <!--/transition-group-->
      </template>
    </div>
  </div>
  <div v-if="photoSelected" id="glightbox-body" class="glightbox-container glightbox-clean" tabindex="-1" role="dialog" aria-hidden="false">
      <div class="gloader visible" style="display: none;"></div>
      <div class="goverlay"></div>
      <div class="gcontainer">
      <div id="glightbox-slider" class="gslider">
        <div class="gslide loaded current">
          <div class="gslide-inner-content">
            <div class="ginner-container desc-bottom">
              <div class="gslide-media gslide-image"><img :src="'/images/albums/'+photoSelected.id_album+'/'+photoSelected.imagen" alt="" aria-labelledby="gSlideTitle_2"></div>
              <div v-if="photoSelected.imagen_titulo || photoSelected.imagen_descripcion || photoSelected.nombre_album" class="gslide-description description-bottom">
                <div class="gdesc-inner">
                  <h4 v-if="photoSelected.imagen_titulo" class="gslide-title" id="gSlideTitle_2">{{ photoSelected.imagen_titulo }}</h4>
                  <p v-if="photoSelected.nombre_album || photoSelected.imagen_descripcion">{{ photoSelected.nombre_album }}<span v-if="photoSelected.nombre_album && photoSelected.imagen_descripcion"> - </span>{{ photoSelected.imagen_descripcion }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button @click="closePhoto()" class="gclose gbtn" aria-label="Close"    data-taborder="3"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" xml:space="preserve"><g><g><path d="M505.943,6.058c-8.077-8.077-21.172-8.077-29.249,0L6.058,476.693c-8.077,8.077-8.077,21.172,0,29.249C10.096,509.982,15.39,512,20.683,512c5.293,0,10.586-2.019,14.625-6.059L505.943,35.306C514.019,27.23,514.019,14.135,505.943,6.058z"></path></g></g><g><g><path d="M505.942,476.694L35.306,6.059c-8.076-8.077-21.172-8.077-29.248,0c-8.077,8.076-8.077,21.171,0,29.248l470.636,470.636c4.038,4.039,9.332,6.058,14.625,6.058c5.293,0,10.587-2.019,14.624-6.057C514.018,497.866,514.018,484.771,505.942,476.694z"></path></g></g></svg></button>
      <button @click="prevPhoto()"  class="gprev gbtn"  aria-label="Previous" data-taborder="2"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"><g><path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"></path></g></svg></button>
      <button @click="nextPhoto()"  class="gnext gbtn"  aria-label="Next"     data-taborder="1"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 477.175 477.175" xml:space="preserve"> <g><path d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z"></path></g></svg></button>
      </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default{
  name: 'AlbumComponent',
  props: {
    'albums':{
      type: Object
    }
  },
  setup(props) {
    // Define a reactive variable to store your data
    const data = ref(null)
    // Define a reactive state variable using ref
    const activeFilter = ref('')
    const activeIndex = ref('')
    const photoSelected = ref(null)
    // Method to change the message
    const changeFilter = (state, index) => {
      activeFilter.value = state
      photoSelected.value = null
      activeIndex.value = null
    }

    const selectPhoto = (photo, index) => {
      photoSelected.value = photo
      activeIndex.value = index
    }
    
    const closePhoto = () => {
      photoSelected.value = null
      activeIndex.value = null
    }
    
    const nextPhoto = () => {
      const photos = filteredImages()
      let next = activeIndex.value + 1
      if(next >= photos.length){
        next = 0
      }
      selectPhoto(photos[next], next)      
    }
    
    const prevPhoto = () => {
      const photos = filteredImages()
      let next = activeIndex.value - 1
      if(next < 0){
        next = photos.length - 1
      }
      selectPhoto(photos[next], next)
    }
    
    // Filter key
    const filterKey = ref('age');
    // Filter value
    const filterValue = ref(30);

    const filteredImages = () => {
      return props.albums?.images.filter(item => (item['id_album'] == activeFilter.value || activeFilter.value == ''))
    }

    return {
      // Expose the data to the template
      activeFilter,
      changeFilter,
      photoSelected,
      activeIndex,
      selectPhoto,
      closePhoto,
      nextPhoto,
      prevPhoto,
      filteredImages,
      data
    }
  }
}
</script>

<style>
/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.album #album-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}

.album #album-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 16px 10px 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  margin: 0 3px 10px 3px;
  transition: all ease-in-out 0.3s;
  background: #f4f4f4;
  border-radius: 50px;
}

.album #album-flters li:hover,
.album #album-flters li.filter-active {
  color: #fff;
  background: #05264f;
}

.album #album-flters li:last-child {
  margin-right: 0;
}

.album .album-item {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
}

.album .album-item img {
  position: relative;
  top: 0;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.album .album-item .album-info {
  opacity: 0;
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: -50px;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: #05264f;
  padding: 15px 20px;
}

.album .album-item .album-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}

.album .album-item .album-info p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}

.album .album-item .album-info .preview-link,
.album .album-item .album-info .details-link {
  position: absolute;
  right: 30px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: white;
  transition: ease-in-out 0.3s;
}

.album .album-item .album-info .preview-link:hover,
.album .album-item .album-info .details-link:hover {
  color: #33ffb4;
}

.album .album-item .album-info .details-link {
  right: 0px;
  top: 0px;
}

.album .album-item img {
  width: 640px;
  height: 350px;
  object-fit: cover;
}
.album .album-item:hover img {
  top: -30px;
}

.album .album-item:hover .album-info {
  opacity: 1;
  bottom: 0;
}



/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.album-details {
  padding-top: 40px;
}

.album-details .album-details-slider img {
  width: 100%;
}

.album-details .album-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.album-details .album-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #05264f;
}

.album-details .album-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #05264f;
}

.album-details .album-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(40, 40, 40, 0.08);
}

.album-details .album-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.album-details .album-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}

.album-details .album-info ul li+li {
  margin-top: 10px;
}

.album-details .album-description {
  padding-top: 30px;
}

.album-details .album-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}

.album-details .album-description p {
  padding: 0;
}

.glightbox-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999 !important;
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
  overflow: hidden;
}

.glightbox-container.inactive {
  display: none;
}

.glightbox-container .gcontainer {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: hidden;
}

.glightbox-container .gslider {
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
  position: relative;
  overflow: hidden;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
  width: 100%;
  position: absolute;
  opacity: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
}

.glightbox-container .gslide.current {
  opacity: 1;
  z-index: 99999;
  position: relative;
}

.glightbox-container .gslide.prev {
  opacity: 1;
  z-index: 9999;
}

.glightbox-container .gslide-inner-content {
  width: 100%;
}

.glightbox-container .ginner-container {
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
  height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
  width: 100%;
}

.glightbox-container .ginner-container.desc-bottom,
        .glightbox-container .ginner-container.desc-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.glightbox-container .ginner-container.desc-left,
        .glightbox-container .ginner-container.desc-right {
  max-width: 100% !important;
}

.gslide iframe,
    .gslide video {
  outline: none !important;
  border: none;
  min-height: 165px;
  -webkit-overflow-scrolling: touch;
  -ms-touch-action: auto;
  touch-action: auto;
}

.gslide:not(.current) {
  pointer-events: none;
}

.gslide-image {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gslide-image img {
  max-height: 100vh;
  display: block;
  padding: 0;
  float: none;
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: 100vw;
  width: auto;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  -ms-touch-action: none;
  touch-action: none;
  margin: auto;
  min-width: 200px;
}

.desc-top .gslide-image img,
        .desc-bottom .gslide-image img {
  width: auto;
}

.desc-left .gslide-image img,
        .desc-right .gslide-image img {
  width: auto;
  max-width: 100%;
}

.gslide-image img.zoomable {
  position: relative;
}

.gslide-image img.dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.gslide-video {
  position: relative;
  max-width: 100vh;
  width: 100% !important;
}

.gslide-video .gvideo-wrapper {
  width: 100%;
        /* max-width: 160vmin; */
  margin: auto;
}

.gslide-video::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 0, 0, 0.34);
  display: none;
}

.gslide-video.playing::before {
  display: none;
}

.gslide-video.fullscreen {
  max-width: 100% !important;
  min-width: 100%;
  height: 75vh;
}

.gslide-video.fullscreen video {
  max-width: 100% !important;
  width: 100% !important;
}

.gslide-inline {
  background: #fff;
  text-align: left;
  max-height: calc(100vh - 40px);
  overflow: auto;
  max-width: 100%;
}

.gslide-inline .ginlined-content {
  padding: 20px;
  width: 100%;
}

.gslide-inline .dragging {
  cursor: -webkit-grabbing !important;
  cursor: grabbing !important;
  -webkit-transition: none;
  transition: none;
}

.ginlined-content {
  overflow: auto;
  display: block !important;
  opacity: 1;
}

.gslide-external {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-width: 100%;
  background: #fff;
  padding: 0;
  overflow: auto;
  max-height: 75vh;
  height: 100%;
}

.gslide-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.zoomed .gslide-media {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.desc-top .gslide-media,
    .desc-bottom .gslide-media {
  margin: 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gslide-description {
  position: fixed;
  bottom: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.gslide-description.description-left,
    .gslide-description.description-right {
  max-width: 100%;
}

.gslide-description.description-bottom,
    .gslide-description.description-top {
  margin: 0 auto;
  width: 100%;
}

.gslide-description p {
  margin-bottom: 12px;
}

.gslide-description p:last-child {
  margin-bottom: 0;
}

.zoomed .gslide-description {
  display: none;
}

.glightbox-button-hidden {
  display: none;
}


/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/

.glightbox-mobile .glightbox-container .gslide-description {
  height: auto !important;
  width: 100%;
  background: transparent;
  position: absolute;
  bottom: 0;
  padding: 19px 11px;
  max-width: 100vw !important;
  -webkit-box-ordinal-group: 3 !important;
  -ms-flex-order: 2 !important;
  order: 2 !important;
  max-height: 78vh;
  overflow: auto !important;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
  color: #fff;
  font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
  color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
  color: #fff;
  font-weight: bold;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
  color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc string {
  color: #fff;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
  color: #fff;
  opacity: 0.4;
}

.gdesc-open .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 0.4;
}

.gdesc-open .gdesc-inner {
  padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  opacity: 1;
}

.greset {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.gabsolute {
  position: absolute;
}

.grelative {
  position: relative;
}

.glightbox-desc {
  display: none !important;
}

.glightbox-open {
  overflow: hidden;
}

.gloader {
  height: 25px;
  width: 25px;
  -webkit-animation: lightboxLoader 0.8s infinite linear;
  animation: lightboxLoader 0.8s infinite linear;
  border: 2px solid #fff;
  border-right-color: transparent;
  border-radius: 50%;
  position: absolute;
  display: block;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 47%;
}

.goverlay {
  width: 100%;
  height: calc(100vh + 1px);
  position: fixed;
  top: -1px;
  left: 0;
  background: #000;
  will-change: opacity;
}

.glightbox-mobile .goverlay {
  background: #000;
}

.gprev,
.gnext,
.gclose {
  z-index: 99999;
  cursor: pointer;
  width: 26px;
  height: 44px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.gprev svg,
.gnext svg,
.gclose svg {
  display: block;
  width: 25px;
  height: auto;
  margin: 0;
  padding: 0;
}

.gprev.disabled,
.gnext.disabled,
.gclose.disabled {
  opacity: 0.1;
}

.gprev .garrow,
.gnext .garrow,
.gclose .garrow {
  stroke: #fff;
}

.gbtn.focused {
  outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
  opacity: 0;
}

.glightbox-closing .gnext,
    .glightbox-closing .gprev,
    .glightbox-closing .gclose {
  opacity: 0 !important;
}


/*Skin */

.glightbox-clean .gslide-description {
  background: #fff;
}

.glightbox-clean .gdesc-inner {
  padding: 22px 20px;
}

.glightbox-clean .gslide-title {
  font-size: 1em;
  font-weight: normal;
  font-family: arial;
  color: #000;
  margin-bottom: 19px;
  line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
  font-size: 0.86em;
  margin-bottom: 0;
  font-family: arial;
  line-height: 1.4em;
}

.glightbox-clean .gslide-video {
  background: #000;
}

.glightbox-clean .gprev,
    .glightbox-clean .gnext,
    .glightbox-clean .gclose {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
}

.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
  fill: #fff;
}

.glightbox-clean .gprev {
  position: absolute;
  top: -100%;
  left: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gnext {
  position: absolute;
  top: -100%;
  right: 30px;
  width: 40px;
  height: 50px;
}

.glightbox-clean .gclose {
  width: 35px;
  height: 35px;
  top: 15px;
  right: 10px;
  position: absolute;
}

.glightbox-clean .gclose svg {
  width: 18px;
  height: auto;
}

.glightbox-clean .gclose:hover {
  opacity: 1;
}


/*CSS Animations*/

.gfadeIn {
  -webkit-animation: gfadeIn 0.5s ease;
  animation: gfadeIn 0.5s ease;
}

.gfadeOut {
  -webkit-animation: gfadeOut 0.5s ease;
  animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
  -webkit-animation: gslideOutLeft 0.3s ease;
  animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
  -webkit-animation: gslideInLeft 0.3s ease;
  animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
  -webkit-animation: gslideOutRight 0.3s ease;
  animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
  -webkit-animation: gslideInRight 0.3s ease;
  animation: gslideInRight 0.3s ease;
}

.gzoomIn {
  -webkit-animation: gzoomIn 0.5s ease;
  animation: gzoomIn 0.5s ease;
}

.gzoomOut {
  -webkit-animation: gzoomOut 0.5s ease;
  animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes lightboxLoader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes gfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes gfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes gslideInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
  }
  to {
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes gslideOutLeft {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(-60%, 0, 0);
    transform: translate3d(-60%, 0, 0);
    opacity: 0;
    visibility: hidden;
  }
}

@-webkit-keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes gslideInRight {
  from {
    opacity: 0;
    visibility: visible;
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}

@keyframes gslideOutRight {
  from {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(60%, 0, 0);
    transform: translate3d(60%, 0, 0);
    opacity: 0;
  }
}

@-webkit-keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}

@keyframes gzoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

@keyframes gzoomOut {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}

@media (min-width: 769px) {
  .glightbox-container .ginner-container {
    width: auto;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .glightbox-container .ginner-container.desc-top .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-top .gslide-image,
                .glightbox-container .ginner-container.desc-top .gslide-image img {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .glightbox-container .ginner-container.desc-left .gslide-description {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .glightbox-container .ginner-container.desc-left .gslide-image {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .gslide-image img {
    max-height: 97vh;
    max-width: 100%;
  }
  .gslide-image img.zoomable {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
  }
  .zoomed .gslide-image img.zoomable {
    cursor: -webkit-grab;
    cursor: grab;
  }
  .gslide-inline {
    max-height: 95vh;
  }
  .gslide-external {
    max-height: 100vh;
  }
  .gslide-description.description-left,
    .gslide-description.description-right {
    max-width: 275px;
  }
  .glightbox-open {
    height: auto;
  }
  .goverlay {
    background: rgba(0, 0, 0, 0.92);
  }
  .glightbox-clean .gslide-media {
    -webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
    box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
  }
  .glightbox-clean .description-left .gdesc-inner,
.glightbox-clean .description-right .gdesc-inner {
    position: absolute;
    height: 100%;
    overflow-y: auto;
  }
  .glightbox-clean .gprev,
    .glightbox-clean .gnext,
    .glightbox-clean .gclose {
    background-color: rgba(0, 0, 0, 0.32);
  }
  .glightbox-clean .gprev:hover,
.glightbox-clean .gnext:hover,
.glightbox-clean .gclose:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .glightbox-clean .gprev {
    top: 45%;
  }
  .glightbox-clean .gnext {
    top: 45%;
  }
}

@media (min-width: 992px) {
  .glightbox-clean .gclose {
    opacity: 0.7;
    right: 20px;
  }
}

@media screen and (max-height: 420px) {
  .goverlay {
    background: #000;
  }
}

.fade-enter-active, .fade-leave-active {
  opacity: 1;
  transition: opacity 0.5s,transform 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: scale(0.5),transform 0.5s;
}
.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s, translateX(-100%);;
}
.slide-enter, .slide-leave-to {
  transform: translateY(-100%);
}
.zoom-fade-enter-active {
  transition: opacity 0.5s, transform 0.5s;
}
.zoom-fade-enter {
  opacity: 0;
  transform: scale(1);
}
.zoom-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.zoom-fade-leave-to {
  opacity: 0;
  transform: scale(0);
}
</style>