import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import * as fetchIntercept from 'fetch-intercept';

const unregister = fetchIntercept.register({
  request: function (url, config = {}) {
    const modifiedUrl = process.env.VUE_APP_API_URL + url
    const authToken = localStorage.getItem("authToken")
    const modifiedAuth = { 'x-access-token': authToken ? authToken : '' } 
    
    let headers = { ...modifiedAuth, }
    const contentTypeJson = {'Content-Type': 'application/json'}
    if(!config?.noContentType){
      headers = { ...headers, ...contentTypeJson }
    }
    const modifiedOptions = {
      ...config,
      headers: {
        ...headers
      }
    }
    return [modifiedUrl, modifiedOptions]
  },

  requestError: function (error) {
    console.log(error)
    return Promise.reject(error)
  },

  response: function (response) {
    /*const clonedResponse = response.clone();
    const json = () =>
      clonedResponse
        .json()
        .then((data) => ({ ...data, title: `Intercepted: ${data.title}` }));*/

    //response.json = json;
    return response
  },

  responseError: function (error) {
    return Promise.reject(error)
  },
});

/*
fetch('https://jsonplaceholder.typicode.com/todos/1')
  .then((response) => response.json())
  .then((json) => console.log(json));
*/
// unregister interceptors
//unregister();

createApp(App).use(store).use(router).use(i18n).mount('#app')

