<template>
{{ semanaText }}
</template>

<script>
import { onMounted } from 'vue';

export default{
    name: 'SemanaComponent',
    props: {
        'date':{
            type: String
        }
    },
    data() {
        return {
            semana: '',
            semanas : ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado', 'Domingo']
        }
    },
    computed: {
        semanaText() {
            const d = new Date()
            if(!this.semana >= 1 && !this.semana <= 7) 
                this.semana= d.getDay() + 1
            return this.semanas[this.semana - 1]
        }
    },
    mounted() {
        const d = new Date(this.date);
        this.semana = d.getDay()
    }
    
}

</script>