import { createStore } from 'vuex'

export default createStore({
  state: {
    authToken: 'invalid',
    routeMeta: null,
    datos: [],
    menu: [],
    isLoading: false,
    loadings: 0,
  },
  getters: {
    loading: state => state.loading
  },
  mutations: {
    setAuthToken(state, token) {
      state.authToken = token;
    },
    setRouteMeta(state, meta) {
      state.routeMeta = meta;
    },
    setDatos(state, payload) {
      state.datos = payload
    },
    setMenu(state, payload) {
      state.menu = payload
    },
    setLoading(state, loading) {
      if(loading) {
        state.loadings++
      } else {
        state.loadings--
      }
      state.isLoading = (state.loadings > 0)
    }
  },
  actions: {
    setAuthToken({ commit }, token) {
      commit('setAuthToken', token);
    },
    setRouteMeta({ commit }, meta) {
      commit('setRouteMeta', meta);
    },
    async fetchDatos({commit}) {
      try {
        const resDatos = await fetch('datos')
        const datos = await resDatos.json()
        commit('setDatos', datos)
      } catch (error) {
        console.log(error)
      }
    },
    async fetchMenu({commit}) {
      try {
        const resMenu = await fetch('menu')
        const menu = await resMenu.json()
        commit('setMenu', menu)
      } catch (error) {
        console.log(error)
      }
    }
  },
  modules: {
  }
})
