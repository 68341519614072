<template>
  <HeaderComponent/>
  <LoadingComponent />
  <router-view/>
  <FooterComponent/>
</template>

<style lang="scss">

</style>

<script>
import { useStore } from 'vuex'
import { onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import FooterComponent from './components/FooterComponent.vue'
import HeaderComponent from './components/HeaderComponent.vue'
import LoadingComponent from './components/general/LoadingComponent.vue'
import { findMenuItemByName } from './utils/common.utils.js'

export default {
  components: {
    FooterComponent,
    HeaderComponent,
    LoadingComponent
  },
  setup(){
    const store = useStore()
    const route = useRoute()

    const changeTitle = (title, subtitle) => {
      document.title = title + ( subtitle ? ' - ' + subtitle : '')
    }
    onMounted(async() => {
      //Load fixed data used in all pages
      await store.dispatch('fetchDatos')
      await store.dispatch('fetchMenu')
      const menu = findMenuItemByName(store.state.menu, route.name)
      const title = store.state.datos?.data?.titulo
      const subtitle = menu?.module_menu 
      //const datos_site = store.state.datos?.data
      changeTitle(title ? title : process.env.VUE_APP_NAME, subtitle)
      
    })

    
    watch(route, (to, from) => {
      // This callback will be executed whenever the route changes
      const menu = findMenuItemByName(store.state.menu, from.name)
      const title = store.state.datos?.data?.titulo
      const subtitle = menu?.module_menu 
      changeTitle(title ? title : process.env.VUE_APP_NAME, subtitle)
    })
  },
  mounted() {
    let selectHeader = select('#header')
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
            selectHeader.classList.add('header-scrolled')
        } else {
            selectHeader.classList.remove('header-scrolled')
        }
      }
      window.addEventListener('load', headerScrolled)
      onscroll(document, headerScrolled)
    }
  },
  methods: {
    assignScroll: function(){
      
    }
  }
}
</script>