<template>
    <form id="form-consulta needs-validation" novalidate @submit.prevent="submitForm" @reset="onReset" method="post" role="form" class="php-email-form">
		<div class="row">
			<div class="col-md-6 form-group">
				<input :class="formData.errores && formData.errores.name ? 'is-invalid' : ''" type="text" v-model="formData.values.name" name="name" class="form-control" id="name" placeholder="Tu Nombre" required>
				<div class="invalid-feedback">Por favor ingrese su nombre.</div>
			</div>
			<div class="col-md-6 form-group mt-3 mt-md-0">
				<input :class="formData.errores && formData.errores.email ? 'is-invalid' : ''" type="email" v-model="formData.values.email"  class="form-control" name="email" id="email" placeholder="Tu Correo Electrónico" required>
				<div class="invalid-feedback">No ha ingresado un email válido.</div>
			</div>
		</div>
		<div class="form-group mt-3">
			<textarea :class="formData.errores && formData.errores.message ? 'is-invalid' : ''" class="form-control" v-model="formData.values.message" name="message" rows="5" placeholder="Tu Consulta" required></textarea>
			<div class="invalid-feedback">Debe escribir el motivo de su contacto.</div>
		</div>
		<div class="my-3">
			<div :class="formData.loading ? 'd-block' : ''" class="loading">Loading</div>
			<div :class="formData.success ? 'd-block' : ''" class="sent-message">Su mensaje ha sido enviado. ¡Muchas Gracias!</div>
			<div :class="formData.error ? 'd-block' : ''" class="error-message">Ocurrió un error por favor intente nuevamente más tarde</div>
		</div>
		<div class="text-end pe-3">
			<button id="btn-consulta" type="submit" class="btn btn-primary">
			<span v-if="!formData.loading" class="txt">Enviar Consulta</span>
			<span v-if="formData.loading" class="action"><i class="bx bx-loader bx-spin"></i> Enviando consulta </span>
			</button>
		</div>
	</form>

</template>

<script>
import { ref } from 'vue'

export default{
    name: 'FormConsultaComponent',
  	props: {
        'src':{
            type: String
        },
        'height':{
            type: Number,
            default: 350
        }
    },
	computed: {
		
	},
	setup(){

		const formValid =() =>{
			let error = false
			formData.value.errores.name = false
			formData.value.errores.email = false
			formData.value.errores.message = false
			
			if(!(formData.value.values.name.length > 0)){
				formData.value.errores.name = true
				error = true
			}
			if(!(/(.+)@(.+){2,}.(.+){2,}/.test(formData.value.values.email))){
				formData.value.errores.email = true
				error = true
			}
			if(!(formData.value.values.message.length > 0)){
				formData.value.errores.message = true
				error = true
			}
			return !( error )
		}

		const formData = ref({
			values: {
				name: '',
				email: '',
				message: ''
			},
			error: false,
			errores: { name: false, email: false, message: false },
			success: false,
			loading: false
		})

		const onReset = () => {
			formData.value.values = {
				...formData.value.values,
				name: '',
				email: '',
				message: ''
			}
		}

		const submitForm = async () => {
			formData.value.error = false
			formData.value.success = false
			formData.value.loading = true

			if(!formValid()){
				formData.value.loading = false
				return false
			}

			await fetch('contact/create', {
				method: 'POST',
				body: JSON.stringify(formData.value.values)
			})
			.then(response => {
				formData.value.loading = false
				if(response.ok) {
					formData.value.success = true
					onReset()
				} else {
					throw new Error("Not 2xx response", {cause: response})
				}
			})
			.catch((error) => {
				formData.value.error = true
			})
		}

		return {
			formData,
			submitForm,
			onReset
		}
	}
}
</script>